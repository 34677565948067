<template>
  <el-dialog title="此次信件寄送對象資訊" :visible.sync="display" width="600px">
    <el-table
      :data="[
        { name: 'Student(s)' },
        { name: 'Parent(s)' },
        { name: 'Teacher(s)' }
      ]"
    >
      <el-table-column label="寄送對象" width="140px">
        <template slot-scope="scope">
          <el-checkbox
            v-if="scope.row.name === 'Student(s)'"
            :true-label="1"
            :false-label="0"
            :value="mailToStudents"
            @input="mailToStudents => $emit('onChangeMailToStudents', mailToStudents)"
          />
          <el-checkbox
            v-else-if="scope.row.name === 'Parent(s)'"
            :true-label="1"
            :false-label="0"
            :value="mailToParents"
            @input="mailToParents => $emit('onChangeMailToParents', mailToParents)"
          />
          <el-checkbox
            v-else-if="scope.row.name === 'Teacher(s)'"
            :true-label="1"
            :false-label="0"
            :value="mailToTeachers"
            @input="mailToTeachers => $emit('onChangeMailToTeachers', mailToTeachers)"
          />
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <ul v-if="scope.row.name === 'Student(s)'">
            <li :key="student" v-for="student in studentsNameAndMail">
              {{ student }}
            </li>
          </ul>
          <ul v-if="scope.row.name === 'Parent(s)'">
            <li :key="parent" v-for="parent in parentsNameAndMail">
              {{ parent }}
            </li>
          </ul>
          <ul v-if="scope.row.name === 'Teacher(s)'">
            <li :key="teacher" v-for="teacher in teachersNameAndMail">
              {{ teacher }}
            </li>
          </ul>
        </template>
      </el-table-column> 
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('onCancel')">
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="$emit('onSubmit')">
        {{ $t("button.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { user } from "@ivy-way/material";

export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    mailToStudents: {
      type: Number,
      default: 0
    },
    mailToParents: {
      type: Number,
      default: 0
    },
    mailToTeachers: {
      type: Number,
      default: 0
    },
    students: {
      type: Array,
      default: () => []
    },
    teachers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    studentsNameAndMail() {
      const studentsNameAndMail = this.students.map(({ new_student }) => (
        `${this.userMethods.displayName(new_student.first_name, new_student.last_name)} <${new_student.email}>`
      ));
      return studentsNameAndMail;
    },
    parentsNameAndMail() {
      let parentsNameAndMail = [];
      this.students.map(({ new_student }) => {
        new_student.parents.forEach((parent) => {
          parentsNameAndMail.push(
            `${this.userMethods.displayName(parent.first_name, parent.last_name)} <${parent.email}>`
          );
        });
      });
      return parentsNameAndMail;
    },
    teachersNameAndMail() {
      const teachersNameAndMail = this.teachers.map(({ new_teacher }) => (
        `${this.userMethods.displayName(new_teacher.first_name, new_teacher.last_name)} <${new_teacher.email}>`
      ));
      return teachersNameAndMail;
    }
  }
};
</script>
