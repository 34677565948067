<template>
  <Ckeditor
    height="600"
    :value="emailContent"
    @input="value => $emit('setIntroNewTeacherMailContent', value, 1)"
  />
</template>

<script>
import moment from "moment";
import { user } from "@ivy-way/material";
import Ckeditor from "@/components/ckeditor/index.vue";

export default {
  name: "MailPreviewer",
  components: {
    Ckeditor
  },
  props: {
    needUpdate: {
      type: Boolean,
      default: true
    },
    isCreateClass: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ""
    },
    students: {
      type: Array,
      default: () => []
    },
    isOnline: {
      type: Number,
      default: 1
    },
    subject: {
      type: String,
      default: ""
    },
    newTeachers: {
      type: Array,
      default: () => []
    },
    teachers: {
      type: Array,
      default: () => []
    },
    // counseling_bio, tutoring_bio
    bioType: {
      type: String,
      default: ""
    },
    hasMeeting: {
      type: Boolean,
      default: true
    },
    theyWillBeMeetings: {
      type: Array,
      default: () => []
    },
    isDecidedStartTime: {
      type: Boolean,
      default: false
    },
    startedDate: {
      type: String,
      default: ""
    },
    startedTime: {
      type: String,
      default: ""
    },
    startedTimezone: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      emailContent: ""
    };
  },
  watch: {
    students() {
      this.updateEmailContent("students");
    },
    isOnline() {
      this.updateEmailContent("isOnline");
    },
    subject() {
      this.updateEmailContent("subject");
    },
    newTeachers(newTeachers, teachers) {
      console.log(teachers,newTeachers);
      if(teachers.length!== newTeachers.length){
        this.updateEmailContent("newTeachers");
      }
    },
    bioType() {
      this.updateEmailContent("bioType");
    },
    theyWillBeMeetings(oldMeetings,newMeetings) {
      console.log(oldMeetings,newMeetings);
      if(
        oldMeetings[0].address !== newMeetings[0].address ||
        oldMeetings[0].date !== newMeetings[0].date ||
        oldMeetings[0].isDecidedMeetingTime !== newMeetings[0].isDecidedMeetingTime ||
        oldMeetings[0].isSameOfClassAddress !== newMeetings[0].isSameOfClassAddress ||
        oldMeetings[0].time !== newMeetings[0].time ||
        oldMeetings[0].timezone !== newMeetings[0].timezone
      ) {
        this.updateEmailContent("theyWillBeMeetings");
      }
    },
    teachers(teachers,newTeachers) {
      // console.log(teachers,newTeachers);
      if(teachers.length!== newTeachers.length){
        this.updateEmailContent("teachers");
      }
    },
    isDecidedStartTime() {
      this.updateEmailContent("isDecidedStartTime");
    },
    startedDate() {
      this.updateEmailContent("startedDate");
    },
    startedTime() {
      this.updateEmailContent("startedTime");
    },
    startedTimezone() {
      this.updateEmailContent("startedTimezone");
    },
    address() {
      this.updateEmailContent("address");
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    studentFirstNames() {
      let studentsNames = [];
      this.students.forEach(({ new_student }) => {
        const hasSameFirsrNameStudents = this.students.filter(
          (student) => student.new_student.first_name === new_student.first_name
        );
        const hasSameFirsrNameTeachers = this.teachers.filter(
          (teacher) => teacher.new_teacher.first_name === new_student.first_name
        );
        if (hasSameFirsrNameStudents.length + hasSameFirsrNameTeachers.length >= 2) {
          studentsNames.push({
            displayName: this.userMethods.displayName(new_student.first_name, new_student.last_name),
            firstName: new_student.first_name,
            lastName: new_student.last_name
          });
        } else {
          studentsNames.push({
            displayName: new_student.first_name,
            firstName: new_student.first_name,
            lastName: new_student.last_name
          });
        }
      });

      let studentFirstNames = "";
      if (studentsNames.length === 1) {
        studentFirstNames = ` ${studentsNames[0].displayName}`;
      } else if (studentsNames.length === 2) {
        studentFirstNames = ` ${studentsNames[0].displayName} and ${studentsNames[1].displayName}`;
      } else if (studentsNames.length === 3) {
        studentsNames.forEach(({ displayName }, index) => {
          if (index !== studentsNames.length - 1) {
            studentFirstNames += `${displayName}, `;
          } else {
            studentFirstNames += `and ${displayName}`;
          }
        });
        studentsNames = ` ${studentsNames}`;
      } else if (studentFirstNames.length >= 4) {
        studentFirstNames = "";
      }
      return studentFirstNames;
    },
    studentFirstNamesWithAt() {
      let studentsNames = [];
      this.students.forEach(({ new_student }) => {
        const hasSameFirsrNameStudents = this.students.filter(
          (student) => student.new_student.first_name === new_student.first_name
        );
        const hasSameFirsrNameTeachers = this.teachers.filter(
          (teacher) => teacher.new_teacher.first_name === new_student.first_name
        );
        if (hasSameFirsrNameStudents.length + hasSameFirsrNameTeachers.length >= 2) {
          studentsNames.push({
            displayName: this.userMethods.displayName(new_student.first_name, new_student.last_name),
            firstName: new_student.first_name,
            lastName: new_student.last_name
          });
        } else {
          studentsNames.push({
            displayName: new_student.first_name,
            firstName: new_student.first_name,
            lastName: new_student.last_name
          });
        }
      });

      let studentFirstNames = "";
      if (studentsNames.length === 1) {
        studentFirstNames = `@${studentsNames[0].displayName}`;
      } else if (studentsNames.length === 2) {
        studentFirstNames = `@${studentsNames[0].displayName} and @${studentsNames[1].displayName}`;
      } else if (studentsNames.length >= 3) {
        studentsNames.forEach(({ displayName }, index) => {
          if (index !== studentsNames.length - 1) {
            studentFirstNames += `@${displayName}, `;
          } else {
            studentFirstNames += `and @${displayName}`;
          }
        });
      }
      return studentFirstNames;
    },
    teacherFirstNames() {
      let teachersNames = [];
      this.newTeachers.forEach(({ new_teacher }) => {
        const hasSameFirsrNameStudents = this.students.filter(
          (student) => student.new_student.first_name === new_teacher.first_name
        );
        const hasSameFirsrNameTeachers = this.teachers.filter(
          (teacher) => teacher.new_teacher.first_name === new_teacher.first_name
        );
        if (hasSameFirsrNameStudents.length + hasSameFirsrNameTeachers.length >= 2) {
          teachersNames.push({
            displayName: this.userMethods.displayName(new_teacher.first_name, new_teacher.last_name),
            firstName: new_teacher.first_name,
            lastName: new_teacher.last_name
          });
        } else {
          teachersNames.push({
            displayName: new_teacher.first_name,
            firstName: new_teacher.first_name,
            lastName: new_teacher.last_name
          });
        }
      });

      let teacherFirstNames = "";
      if (teachersNames.length === 1) {
        teacherFirstNames = teachersNames[0].displayName;
      } else if (teachersNames.length === 2) {
        teacherFirstNames = `${teachersNames[0].displayName} and ${teachersNames[1].displayName}`;
      } else if (teachersNames.length >= 3) {
        teachersNames.forEach(({ displayName }, index) => {
          if (index !== teachersNames.length - 1) {
            teacherFirstNames += `${displayName}, `;
          } else {
            teacherFirstNames += `and ${displayName}`;
          }
        });
      }
      return teacherFirstNames;
    },
    isOnlyOneTeacher() {
      return this.newTeachers.length === 1;
    },
    teacherBio() {
      return this.getTeacherBio(this.newTeachers[0]);
    },
    teachersBio() {
      let teachersBio = "";
      this.newTeachers.forEach(teacher => {
        let bio = this.getTeacherBio(teacher);
        if (bio) {
          teachersBio += `${bio}<br/><br/>`;
        }
      });
      return teachersBio;
    },
    studentsAndTeachersContactMethods() {
      let contactMethods = "";
      this.newTeachers.forEach(({ new_teacher }) => {
        contactMethods += `${this.userMethods.displayName(new_teacher.first_name, new_teacher.last_name)}: ${new_teacher.email}<br/>`;
      });
      this.students.forEach(({ new_student }) => {
        contactMethods += `${this.userMethods.displayName(new_student.first_name, new_student.last_name)}: ${new_student.email}<br/>`;
      });
      return contactMethods;
    },
    theyWillBeMeetingsInfo() {
      let theyWillBeMeetingsInfo = "";
      if (this.theyWillBeMeetings.length === 1) {
        const theyWillBeMeeting = this.theyWillBeMeetings[0];
        theyWillBeMeetingsInfo = `
          ${theyWillBeMeeting.isDecidedMeetingTime ? `<strong>Meeting time:</strong> ${moment(`${theyWillBeMeeting.date} ${theyWillBeMeeting.time}`).format("YYYY-MM-DD LT")} (${theyWillBeMeeting.timezone})<br/>` : ""}
          ${theyWillBeMeeting.address ? `<strong>Meeting location:</strong> ${theyWillBeMeeting.address}<br/>` : ""}
          <br/>
        `;
      } else {
        this.theyWillBeMeetings.forEach((theyWillBeMeeting, index) => {
          let participantsNames = [];
          this.students.forEach(({ new_student }) => {
            const hasSameFirsrNameStudents = this.students.filter(
              (student) => student.new_student.first_name === new_student.first_name
            );
            const hasSameFirsrNameTeachers = this.teachers.filter(
              (teacher) => teacher.new_teacher.first_name === new_student.first_name
            );
            if (hasSameFirsrNameStudents.length + hasSameFirsrNameTeachers.length >= 2) {
              participantsNames.push({
                displayName: this.userMethods.displayName(new_student.first_name, new_student.last_name),
                firstName: new_student.first_name,
                lastName: new_student.last_name
              });
            } else {
              participantsNames.push({
                displayName: new_student.first_name,
                firstName: new_student.first_name,
                lastName: new_student.last_name
              });
            }
          });

          const meetingTeachers = this.newTeachers.filter(
            ({ new_teacher_user_id }) => theyWillBeMeeting.teachersId.includes(new_teacher_user_id)
          );

          meetingTeachers.forEach(({ new_teacher }) => {
            const hasSameFirsrNameStudents = this.students.filter(
              (student) => student.new_student.first_name === new_teacher.first_name
            );
            const hasSameFirsrNameTeachers = this.teachers.filter(
              (teacher) => teacher.new_teacher.first_name === new_teacher.first_name
            );

            if (hasSameFirsrNameStudents.length + hasSameFirsrNameTeachers.length >= 2) {
              participantsNames.push({
                displayName: this.userMethods.displayName(new_teacher.first_name, new_teacher.last_name),
                firstName: new_teacher.first_name,
                lastName: new_teacher.last_name
              });
            } else {
              participantsNames.push({
                displayName: new_teacher.first_name,
                firstName: new_teacher.first_name,
                lastName: new_teacher.last_name
              });
            }
          });

          let participantsDisplayNames = "";
          if (participantsNames.length === 1) {
            participantsDisplayNames = participantsNames[0].displayName;
          } else if (participantsNames.length === 2) {
            participantsDisplayNames = `${participantsNames[0].displayName} and ${participantsNames[1].displayName}`;
          } else if (participantsNames.length >= 3) {
            participantsNames.forEach((participantsName, index) => {
              if (index !== participantsNames.length - 1) {
                participantsDisplayNames += `${participantsName.displayName}, `;
              } else {
                participantsDisplayNames += `and ${participantsName.displayName}`;
              }
            });
          }
          theyWillBeMeetingsInfo += `
            <strong>Meet-and-Greet #${index + 1}:</strong>
            <br/>
            ${theyWillBeMeeting.isDecidedMeetingTime ? `<strong>Meeting time:</strong> ${moment(`${theyWillBeMeeting.date} ${theyWillBeMeeting.time}`).format("YYYY-MM-DD LT")} (${theyWillBeMeeting.timezone})<br/>` : ""}
            ${theyWillBeMeeting.address ? `<strong>Meeting location:</strong> ${theyWillBeMeeting.address}<br/>` : ""}
            <strong>Participants:</strong> ${participantsDisplayNames}
            <br/><br/>
          `;
        });
      }
      return theyWillBeMeetingsInfo;
    },
    firstClassPrepare() {
      return `
        ${this.isDecidedStartTime ? `<strong>First class time:</strong> ${moment(`${this.startedDate} ${this.startedTime}`).format("YYYY-MM-DD LT")} (${this.startedTimezone})<br/>` : ""}
        ${this.address ? `<strong>First class location:</strong> ${this.address}<br/>` : ""}
        <br/>
        ${this.studentFirstNamesWithAt}, if you have specific materials or topics that you'd like to go over, please share them with ${this.teacherFirstNames}. If you don't, it's okay too.
      `;
    }
  },
  created() {
    window.moment = moment;
    this.updateEmailContent("created");
  },
  methods: {
    getTeacherBio(teacher) {
      let bio = teacher.new_teacher[this.bioType];
      if (typeof bio === "string") {
        bio = JSON.parse(bio);
      }
      return `${this.userMethods.displayName(teacher.new_teacher.first_name, teacher.new_teacher.last_name)}: ${bio.en || ""}`;
    },
    updateEmailContent(v) {
      console.log(v);
      let emailContent = `
        Hi${this.studentFirstNames}!
        <br/><br/>

        Please meet your ${this.isOnline ? "online" : "in-person"} ${this.subject} instructor${this.newTeachers.length > 1 ? "s" : ""}, ${this.teacherFirstNames}! ${this.isOnlyOneTeacher ? this.teacherBio : ""} 
        <br/><br/>

        ${this.isOnlyOneTeacher ? "" : this.teachersBio}

        We will make sure everything goes as well as possible. Please communicate with your instructor${this.newTeachers.length > 1 ? "s" : ""} whenever you have any questions. The more we can communicate, the better we can help you!
        <br/><br/>

        We've created a class page for you on our website! You can go to your classroom page <a href="https://ivy-way.com/private/private-lessons/${this.isCreateClass ? "${class_id}" : this.id}/detail" target="_blank">here</a> to see class details, review your lesson logs, and keep track of your learning progress.
        <br/><br/>

        Next Steps:
        <br/>
        1. Add each other's contact information
        <br/>
        Here is everyone's email. Please remember to add each other!
        <br/>
        ${this.studentsAndTeachersContactMethods}
        <br/>

      `;

      if (this.hasMeeting) {
        emailContent += `
          2. Have your Meet-and-Greet (10-30 minutes)
          <br/>
          Meet-and-Greet is a chance for the instructor${this.newTeachers.length > 1 ? "s" : ""} and student${this.students.length > 1 ? "s" : ""} to get to know each other and be on the same page about your future lessons.
          <br/>
          ${this.theyWillBeMeetingsInfo}

          3. Prepare for your first class
          <br/>${this.firstClassPrepare}<br/><br/>
        `;
      } else {
        emailContent += `
          2. Prepare for your first class
          <br/>${this.firstClassPrepare}<br/><br/>
        `;
      }

      emailContent += "As always, if there is anything we can help, please feel free to let us know!";
      this.emailContent = emailContent;
      this.$emit("setIntroNewTeacherMailContent", this.emailContent);
    }
  }
};
</script>

<style scoped></style>
