<template>
  <el-form label-position="right" label-width="150px">
    <el-form-item required label="Participants">
      <el-checkbox-group
        :value="theyWillBeMeeting.teachersId"
        @input="teachersId => $emit('setTheyWillBeMeeting', { teachersId })"
      >
        <el-checkbox
          :key="new_teacher_user_id"
          :value="new_teacher_user_id"
          :label="new_teacher_user_id"
          v-for="{ new_teacher, new_teacher_user_id } in teachers"
        >
          {{
            userMethods.displayName(
              new_teacher.first_name,
              new_teacher.last_name
            )
          }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="Meeting time">
      <el-radio-group
        :value="theyWillBeMeeting.isDecidedMeetingTime"
        @input="
          isDecidedMeetingTime =>
            $emit('setTheyWillBeMeeting', { isDecidedMeetingTime })
        "
      >
        <el-radio :label="false">待定</el-radio>
        <el-radio :label="true">已安排</el-radio>
      </el-radio-group>
      <template v-if="theyWillBeMeeting.isDecidedMeetingTime">
        <span>：</span>
        <el-date-picker
          value-format="yyyy-MM-dd"
          :value="theyWillBeMeeting.date"
          @input="date => $emit('setTheyWillBeMeeting', { date })"
          type="date"
          placeholder="日期"
          style="width: 150px"
        />
        <el-time-select
          :value="theyWillBeMeeting.time"
          @input="time => $emit('setTheyWillBeMeeting', { time })"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:45'
          }"
          placeholder="時間"
          style="width: 140px"
        />
        <TimezoneSelector
          :value="theyWillBeMeeting.timezone"
          @change="timezone => $emit('setTheyWillBeMeeting', { timezone })"
        />
      </template>
    </el-form-item>
    <el-form-item label="Meeting location">
      <!-- <el-radio-group
        :value="theyWillBeMeeting.isSameOfClassAddress"
        @input="
          isSameOfClassAddress => {
            $emit('setTheyWillBeMeeting', {
              isSameOfClassAddress,
              text: isSameOfClassAddress ? privateLessonAddress : ''
            });
          }
        "
      >
        <el-radio :label="true">同上課地點</el-radio>
        <el-radio :label="false">其他地點</el-radio>
      </el-radio-group> -->
      <!-- <el-radio-group
        v-model="theyWillBeMeeting.isSameOfClassAddress"
      >
        <el-radio :label="true">同上課地點</el-radio>
        <el-radio :label="false">其他地點</el-radio>
      </el-radio-group> -->
      <el-input
        type="textarea"
        :rows="3"
        placeholder="請清楚描述，實體碰面要附地址，線上碰面要給線上教室連結，不要只寫 “classroom” “online” 之類的"
        :value="theyWillBeMeeting.address"
        @input="address => $emit('setTheyWillBeMeeting', { address })"
      />
      <!-- <el-input
        v-if="!theyWillBeMeeting.isSameOfClassAddress"
        placeholder="請清楚描述，實體碰面要附地址，線上碰面要給線上教室連結，不要只寫 “classroom” “online” 之類的"
        v-model="theyWillBeMeeting.address"
      /> -->
    </el-form-item>
  </el-form>
</template>

<script>
import { user } from "@ivy-way/material";
import { TimezoneSelector } from "@/components/selector";
import formMixin from "@/mixins/form";

export default {
  props: {
    teachers: {
      type: Array,
      default: () => []
    },
    theyWillBeMeeting: {
      type: Object,
      default: () => ({})
    },
    privateLessonAddress: {
      type: String,
      default: ""
    }
  },
  watch:{
    privateLessonAddress() {
      if(this.privateLessonAddress){
        this.theyWillBeMeeting.address = this.privateLessonAddress;
      }
    },
    theyWillBeMeeting() {
      if(this.privateLessonAddress){
        this.theyWillBeMeeting.address = this.privateLessonAddress;
      }
    }
  },
  components: {
    TimezoneSelector
  },
  mixins: [formMixin],
  computed: {
    userMethods() {
      return user;
    }
  }
};
</script>
